.articlesSection {
    display: flex;
    justify-content: space-around;

    & > * + * {
        margin-left: 2rem;
    }

    @media (--screen-lt-lg) {
        flex-direction: column;

        & > * + * {
            margin-left: 0;
        }
    }
}

.descriptionContainer {
    width: 360px;

    p {
        margin-top: var(--space-16);
    }

    @media (--screen-lt-lg) {
        width: unset;
        max-width: 550px;
        text-align: center;
        margin: 0 auto var(--space-32);
    }

    @media (--screen-lt-md) {
        text-align: left;
        margin-left: unset;
        margin-right: unset;
    }
}

.articlesContainer {
    display: flex;
    justify-content: space-between;

    & > * + * {
        margin-left: 2rem;
    }

    @media (--screen-lt-md) {
        flex-direction: column;

        & > * + * {
            margin-top: 2rem;
            margin-left: 0;
        }
    }

    > article {
        max-width: 310px;
        flex: 1 0 0;

        @media (--screen-lt-lg) {
            max-width: unset;
        }
    }
}
