.mapSection {
    overflow: hidden;
    text-align: center;
    position: relative;

    .textContainer {
        h2 {
            margin-bottom: var(--space-16);
        }

        .subtitle {
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }

        @media (--screen-lt-md) {
            padding-left: var(--space-16);
            padding-right: var(--space-16);
        }
    }
}

.map {
    left: 50%;
    position: relative;
    max-width: 80rem;
    inline-size: var(--map-inline-size, 100%);
    block-size: auto;
    aspect-ratio: 1665/716;
    margin: var(--space-64) 0;
    transform: translateX(-50%);

    @media (--screen-lt-md) {
        --map-inline-size: 800px;
        margin: var(--space-32);
    }
}

.numbersContainer {
    display: flex;
    position: relative;
    justify-content: center;

    .copies {
        width: 150px;

        > p {
            color: var(--color-grey-07);
        }
    }

    .mobileStatsDeco {
        left: 50%;
        top: 50%;
        width: 600px;
        position: absolute;
        bottom: var(--space-64);
        transform: translate(-50%, -50%);
        z-index: -1;

        @media (--screen-gt-md) {
            display: none;
        }

        @media (--screen-lt-sm) {
            width: 475px;
        }
    }

    .perceptionalLine {
        top: 20px;
        margin: 0 16px;
        position: relative;

        &.yellow {
            width: 66px;
            height: 7px;
        }
        &.red {
            width: 61px;
            height: 7px;
        }
        &.blue {
            width: 56px;
            height: 7px;
        }
    }

    @media (--screen-lt-md) {
        display: grid;
        gap: var(--space-16);
        align-items: start;
        grid-template-columns: repeat(2, 1fr);

        .copies {
            width: auto;
            background: white;
            padding: var(--space-32) var(--space-16) var(--space-24);
            box-shadow: var(--shadow-md);
            border-radius: var(--border-radius-base);
        }

        .perceptionalLine {
            display: none;
        }
    }
}
