.productsSection {
    overflow: hidden;
    position: relative;

    .illustrations {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}

.contents {
    position: relative;
    text-align: center;

    @media (--screen-lt-md) {
        padding-left: var(--space-32);
        padding-right: var(--space-32);
    }

    h2 {
        max-width: 18em;
        display: inline-block;
        margin-bottom: var(--space-64);

        @media (--screen-lt-lg) {
            margin-bottom: var(--space-48);
        }

        @media (--screen-lt-md) {
            margin-bottom: var(--space-32);
        }
    }
}

.productsContainer {
    display: flex;
    text-align: left;
    justify-content: space-around;

    a.productLogo {
        display: flex;

        @media (--screen-lt-md) {
            justify-content: center;
        }
    }

    > div {
        max-width: 400px;
        margin-bottom: var(--space-48);

        @media (--screen-lt-md) {
            max-width: unset;
            width: 100%;
        }
    }
    hr {
        margin-bottom: var(--space-48);
    }
    svg {
        height: 2.5rem;
    }
    p {
        margin: var(--space-32) 0 var(--space-32);
        max-width: 400px;
    }

    @media (--screen-lt-lg) {
        & > * + * {
            margin-left: 3rem;
        }

        > div {
            margin-bottom: var(--space-32);
        }
        hr {
            margin-bottom: var(--space-32);
        }
        svg {
            height: 40px;
        }
        p {
            margin: var(--space-32) auto;
        }
    }

    @media (--screen-lt-md) {
        align-items: center;
        flex-direction: column;
        text-align: center;

        & > * + * {
            margin-left: 0;
        }
    }
}
