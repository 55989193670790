.heroSection {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: center;
    align-items: center;
}

.heroText {
    padding-block-start: 0;
    padding-block-end: 0;
    margin-block-start: var(--space-48);
    margin-block-end: var(--space-48);
}

.pill {
    padding: var(--space-8) var(--space-12);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-base);
    background-color: var(--color-black-02);
    margin-bottom: var(--space-48);

    > p {
        padding: var(--space-8);
        color: var(--color-grey-08);
        margin-right: var(--space-16);
        background-color: var(--color-black-03);
        border-radius: var(--radius-sm);
    }
}

.heading {
    max-width: 12em;
    display: inline-block;
}

.illustration {
    max-inline-size: 83.75rem;
    block-size: auto;
    inline-size: 100%;
    aspect-ratio: 342/125;
}

@media (--screen-lt-md) {
    .heroText {
        padding-top: var(--space-64);
    }
}

@media (--screen-lt-sm) {
    .heroText {
        padding-block-start: var(--space-64);
    }
}
